// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slQDa{background:#fcfcfd;border-radius:8px;box-shadow:0px 4px 14px rgba(40,40,99,.24)}.slQDa.Q1Q7y{padding:16px}.slQDa.N90w_{position:relative;z-index:550}.RverE{position:absolute;width:29px;height:9px}.QQHye{position:absolute;z-index:549;box-shadow:0px 4px 14px rgba(40,40,99,.24);width:10px;height:10px}.y_lvK{position:absolute;z-index:551;color:#fcfcfd}.xYUgD .N90w_{bottom:8px}.xYUgD .RverE{bottom:0}.xYUgD .QQHye{transform:translate(10px, -4px) rotate(45deg)}.xYUgD .y_lvK{transform:rotate(0deg)}.grsyy .N90w_{top:8px}.grsyy .RverE{top:0}.grsyy .QQHye{transform:translate(10px, 2px) rotate(45deg)}.grsyy .y_lvK{transform:rotate(180deg)}.M5w_t .N90w_{right:8px}.M5w_t .RverE{right:0}.M5w_t .QQHye{transform:translateX(17px) rotate(45deg)}.M5w_t .y_lvK{transform:translateX(10px) rotate(270deg)}.ygLWz .N90w_{left:8px}.ygLWz .RverE{left:0}.ygLWz .QQHye{transform:translateX(3px) rotate(45deg)}.ygLWz .y_lvK{transform:translateX(-10px) rotate(90deg)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"blue45": `#fcfcff`,
	"blue48": `#f5f7fd`,
	"blue50": `#f3f5ff`,
	"blue300": `#dae0ff`,
	"blue500": `#c0caff`,
	"blue600": `#a1aef5`,
	"blue700": `#8296ff`,
	"blue800": `#6176e7`,
	"dark50": `#fcfcfd`,
	"dark70": `#f4f4f7`,
	"dark100": `#eaeaef`,
	"dark120": `#e4e4ec`,
	"dark200": `#d4d4e0`,
	"dark300": `#bfbfd0`,
	"dark400": `#a9a9c1`,
	"dark500": `#9393b1`,
	"dark600": `#7e7ea1`,
	"dark700": `#696992`,
	"dark800": `#535382`,
	"dark900": `#282863`,
	"green45": `#eff6fa`,
	"green50": `#f3fcfa`,
	"green300": `#b6ebdf`,
	"green500": `#86ddca`,
	"green700": `#56d0b5`,
	"green800": `#36b89b`,
	"green900": `#089475`,
	"orange50": `#fdf2dc`,
	"orange300": `#fadea8`,
	"orange500": `#f9d797`,
	"orange700": `#f7ca75`,
	"orange800": `#f5bd52`,
	"orange900": `#dd9306`,
	"purple50": `#e1d9fe`,
	"purple300": `#c3b3fc`,
	"purple500": `#a58efb`,
	"purple700": `#8768f9`,
	"purple800": `#6942f8`,
	"purple900": `#4520cc`,
	"red200": `#fdf0f0`,
	"red300": `#fee3e3`,
	"red500": `#f9d5d5`,
	"red700": `#f3aaaa`,
	"red900": `#eb7272`,
	"red950": `#e86161`,
	"red970": `#dd4c4c`,
	"white": `#fff`,
	"defaultTextStandard": `#282863`,
	"defaultTextSecondary": `#696992`,
	"defaultButtonPrimary": `#6942f8`,
	"buttonPrimary": `var(--button-primary, #6942f8)`,
	"buttonPrimaryDark": `var(--button-primary-dark, #4520cc)`,
	"buttonDisabled": `var(--button-disabled, #bfbfd0)`,
	"buttonDisabledDark": `var(--button-disabled-dark, #a9a9c1)`,
	"defaultButtonDisabled": `#bfbfd0`,
	"defaultStepperCompleted": `#089475`,
	"defaultStepperIncomplete": `#d4d4e0`,
	"defaultButtonSecondary": `#fcfcfd`,
	"defaultBgStandard": `#f3f5ff`,
	"defaultBgLight": `#fff`,
	"sh10": `0px 4px 12px rgba(40,40,99,.06)`,
	"sh20": `0px 4px 16px rgba(40,40,99,.08)`,
	"sh40": `0px 4px 24px rgba(40,40,99,.12)`,
	"sh60": `0px 4px 14px rgba(40,40,99,.24)`,
	"page": `300`,
	"popover": `550`,
	"popoverContent": `slQDa`,
	"builtInPadding": `Q1Q7y`,
	"withArrow": `N90w_`,
	"arrow": `RverE`,
	"arrowShadow": `QQHye`,
	"arrowCover": `y_lvK`,
	"top": `xYUgD`,
	"bottom": `grsyy`,
	"left": `M5w_t`,
	"right": `ygLWz`
};
export default ___CSS_LOADER_EXPORT___;
