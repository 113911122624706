// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HVNQ3{cursor:pointer}.HVNQ3:disabled{cursor:not-allowed;opacity:.3}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `HVNQ3`
};
export default ___CSS_LOADER_EXPORT___;
