// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.XWfzL{display:grid;grid-template-rows:repeat(1, 1fr);grid-template-columns:repeat(12, 1fr);column-gap:16px}.KVCqB{row-gap:40px}.OQiT1{row-gap:32px;column-gap:24px}.VjjH0{row-gap:20px}.K1K59{align-items:flex-start}.fW3_Z{align-items:flex-end}.N0l30{align-items:stretch}.coZ8W{align-items:center}.omnKA{align-items:baseline}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `XWfzL`,
	"largeGap": `KVCqB`,
	"mediumGap": `OQiT1`,
	"smallGap": `VjjH0`,
	"startAlign": `K1K59`,
	"endAlign": `fW3_Z`,
	"stretchAlign": `N0l30`,
	"centerAlign": `coZ8W`,
	"baselineAlign": `omnKA`
};
export default ___CSS_LOADER_EXPORT___;
