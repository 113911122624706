import { getRequiredValidator } from "./required";
import emailValidator from 'email-validator';

/** true means there's no error. false means there's an error. string means there's an error and gives the error-message. */
export const getEmailValidator = function getEmailValidator() {
  let errorMsg = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'Please enter a valid email address';
  let required = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  return value => {
    const requiredError = getRequiredValidator(required)(value);
    if (requiredError !== true) {
      return requiredError;
    }
    if (value && typeof value === 'string' && !emailValidator.validate(value)) {
      return errorMsg;
    }
    return true;
  };
};

/** For react-hook-form library. */
export const emailValidation = function emailValidation() {
  let errorMsg = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'Please enter a valid email address';
  let opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return {
    validate: {
      email: getEmailValidator(errorMsg, opts.required)
    },
    dataAttr: {
      'data-validation': 'email'
    }
  };
};