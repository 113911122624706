// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ZBSXm,.U7ZYp{display:flex;justify-content:center;flex-direction:column;align-items:center;width:100%}.U7ZYp{margin-top:24px;margin-bottom:26px}.E4sWW{display:flex;flex-wrap:wrap;gap:16px}.CZ3l1{text-align:center}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `ZBSXm`,
	"textualContent": `U7ZYp`,
	"actionsContainer": `E4sWW`,
	"message": `CZ3l1`
};
export default ___CSS_LOADER_EXPORT___;
