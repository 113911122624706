import { useEffect } from 'react';
import { useGlobalContext } from "./GlobalContext";
export function FunctionalLoader(_ref) {
  let {
    show,
    name = 'FunctionalLoader'
  } = _ref;
  const {
    startLoader,
    stopLoader
  } = useGlobalContext();
  useEffect(() => {
    if (show) {
      startLoader(name);
      return () => {
        stopLoader(name);
      };
    }
  }, [show, name]);
  return null;
}