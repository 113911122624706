// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GF9eN{display:flex;align-items:center;padding:0 16px}.sf9JS{margin:0 8px}.pM64l{width:24px;display:flex;justify-content:center}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `GF9eN`,
	"sep": `sf9JS`,
	"actionIcon": `pM64l`
};
export default ___CSS_LOADER_EXPORT___;
