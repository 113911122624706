import { useCallback, useRef } from 'react';

/**
 * Stores a given state in a react ref object. Can be used to store props that
 * need to be used inside a useEffect but we don't want to add them to its dependency array.
 *
 * @example
 * const getStateInRef = useStateInRef(props);
 *
 * // We can call fetchData in the useEffect without needing to add it to the deps array.
 * useEffect(() => {
 *    getStateInRef().fetchData();
 * }, []);
 */
export const useStateInRef = state => {
  const ref = useRef(state);
  ref.current = state;
  return useCallback(() => ref.current, []);
};