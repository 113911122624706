import { getGenericError, getMaxLengthValidator, getMinLengthValidator, getPatternValidator, zipCodeUsPatternValidator } from "./rawValidators";
import { getRequiredValidator } from "./required";
const maxLength = 10;
const minLength = 5;
const maxLengthValidator = getMaxLengthValidator(maxLength);
const minLengthValidator = getMinLengthValidator(minLength);
const postalCodePatternValidator = getPatternValidator(/^[0-9-]*$/);

/** true means there's no error. false means there's an error. string means there's an error and gives the error-message. */
export function getPostalCodeValidator() {
  let valueName = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'Postal code';
  let required = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  const requiredValidator = getRequiredValidator(required);
  const genericError = getGenericError(valueName);
  return value => {
    const requiredError = requiredValidator(value);
    if (requiredError !== true) {
      return requiredError;
    }
    if (!maxLengthValidator(value) || !minLengthValidator(value) || !zipCodeUsPatternValidator(value) || !postalCodePatternValidator(value)) {
      return genericError;
    }
    return true;
  };
}

/** For react-hook-form library. */
export const postalCodeValidation = function postalCodeValidation(valueName) {
  let {
    required
  } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return {
    validate: {
      postalCode: getPostalCodeValidator(valueName, required)
    },
    dataAttr: {
      'data-validation': 'postal-code'
    }
  };
};