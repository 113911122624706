import { getRequiredValidator } from "./required";
import { getGenericError, getMaxLengthValidator, getPatternValidator } from "./rawValidators";
const maxLength = 30;
const maxLengthValidator = getMaxLengthValidator(maxLength);
const cityNamePatternValidator = getPatternValidator(/^[a-zA-Z0-9 ,.()'-]*$/);

/** true means there's no error. false means there's an error. string means there's an error and gives the error-message. */
export function getCityNameValidator() {
  let valueName = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'City name';
  let required = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  const requiredValidator = getRequiredValidator(required);
  const genericError = getGenericError(valueName);
  return value => {
    const requiredError = requiredValidator(value);
    if (requiredError !== true) {
      return requiredError;
    }
    if (!maxLengthValidator(value) || !cityNamePatternValidator(value)) {
      return genericError;
    }
    return true;
  };
}

/** For react-hook-form library. */
export const cityNameValidation = function cityNameValidation(valueName) {
  let {
    required
  } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return {
    validate: {
      cityName: getCityNameValidator(valueName, required)
    },
    dataAttr: {
      'data-validation': 'city'
    }
  };
};