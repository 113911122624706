// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RSJxE{display:flex;align-items:center;gap:8px}.SsPJ6{margin-inline-start:8px;display:flex;gap:4px;align-items:center}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `RSJxE`,
	"label": `SsPJ6`
};
export default ___CSS_LOADER_EXPORT___;
