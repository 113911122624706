function numberWithCommas(money) {
  const decimalPoint = money.isInteger() ? 0 : 2;
  return money.toFixed(decimalPoint).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}
export function amount(money) {
  return money.amount;
}
export function display(money) {
  if (money.isNegative()) {
    return "-$".concat(numberWithCommas(money.abs()));
  } else {
    return "$".concat(numberWithCommas(money));
  }
}
export function isMoney(a) {
  return 'amount' in a;
}