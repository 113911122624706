import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useStateInRef } from "./useStateInRef";

// eslint-disable-next-line @typescript-eslint/no-explicit-any

/**
 * Manages state from the URL search string.
 * configure by passing an object whose keys are mapped to the URL search string keys.
 * multi - this state item is an array and is serialized appropriately (comma-delimited) in the URL.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useStateFromUrl = config => {
  const [initialConfig] = useState(config);
  const location = useLocation();
  const history = useHistory();
  const didMount = useRef(false);
  const getStateInRef = useStateInRef({
    location,
    initialConfig
  });
  const relevantLocationSearch = useMemo(() => {
    const params = new URLSearchParams(location.search);
    const relevantParams = new URLSearchParams();
    Object.keys(getStateInRef().initialConfig).forEach(key => {
      const original = params.get(key);
      if (original != null) {
        relevantParams.set(key, original);
      }
    });
    return "?".concat(relevantParams);
  }, [location.search]);
  const urlState = useMemo(() => {
    const state = {};
    const conf = getStateInRef().initialConfig;
    Object.keys(conf).forEach(key => {
      const delimeter = conf[key].delimeter || ',';
      const urlValue = new URLSearchParams(relevantLocationSearch).get(key);
      const {
        multi,
        defaultValue,
        applyDefaultOnlyOnMount
      } = conf[key];
      const shouldApplyDefault = !applyDefaultOnlyOnMount || !didMount.current;
      if (multi) {
        state[key] = urlValue ? urlValue.split(delimeter) : shouldApplyDefault ? defaultValue || [] : [];
      } else {
        state[key] = urlValue || (shouldApplyDefault ? defaultValue || '' : '');
      }
    });
    return state;
  }, [relevantLocationSearch]);
  useEffect(() => {
    didMount.current = true;
  }, []);
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    Object.keys(initialConfig).forEach(key => {
      const delimeter = initialConfig[key].delimeter || ',';
      const isMulti = initialConfig[key].multi;
      const hasValue = isMulti ? !!urlState[key].length : !!urlState[key];
      if (!params.has(key) && hasValue) {
        if (isMulti) {
          params.set(key, urlState[key].join(delimeter));
        } else {
          params.set(key, urlState[key]);
        }
        history.replace("".concat(location.pathname, "?").concat(params.toString()));
      }
    });
  });
  const changeUrlState = useCallback(function (newState) {
    let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    const {
      initialConfig: conf,
      location: loc
    } = getStateInRef();
    const params = new URLSearchParams(loc.search);
    Object.keys(newState).forEach(key => {
      const delimeter = conf[key].delimeter || ',';
      if (conf[key].multi) {
        if (newState[key].length) {
          params.set(key, newState[key].join(delimeter));
        } else {
          params.delete(key);
        }
      } else {
        if (newState[key]) {
          params.set(key, newState[key]);
        } else {
          params.delete(key);
        }
      }
    });
    const newURL = "".concat(loc.pathname, "?").concat(params.toString());
    if (options.push) {
      history.push(newURL);
    } else {
      history.replace(newURL);
    }
  }, []);
  return {
    urlState,
    changeUrlState
  };
};