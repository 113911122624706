/**
 * Used in an onKeydown event handler for input[type=number].
 * Number inputs allow to input "-", "+", "e" and we don't want that behaviour.
 */
export const preventBadNumberInputKeys = (e, disallowMinus) => {
  const regex = disallowMinus ? /^[-+e]$/ : /^[+e]$/;
  e.key.match(regex) && e.preventDefault();
};

/**
 * Used in an onChange event handler for input[type=number].
 * Number inputs allow to input '01'. In this case we should clear the starting '0'.
 */
export const preventBadNumberInputValues = e => {
  const value = e.currentTarget.value;
  if (value.length > 1 && value.startsWith('0') && value.charAt(1) !== '.') {
    // We need to manually clear the starting '0' because we have bugs where the user
    // sets the value as '0', then enters '1' and the rendered value is '01'.
    e.currentTarget.value = value.substring(1);
  }
};