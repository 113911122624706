import Decimal from 'decimal.js';
import { moneyModel } from "./..";
export function fromDTO(dto) {
  const amount = new Decimal(dto.amount).div(100).toDP(2);
  return moneyModel.make(amount);
}
export function toDTO(money) {
  return {
    amount: money.amount.toDP(2).mul(100).toNumber()
  };
}