// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ku6Qs{display:flex;flex-direction:column;gap:24px;align-items:flex-start}.jysJY{width:400px;display:flex;justify-content:space-between;align-items:center}.Zsecd{display:flex;flex-direction:column;gap:6px}.n4NUG{display:flex;gap:8px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `ku6Qs`,
	"idTitle": `jysJY`,
	"idList": `Zsecd`,
	"idItem": `n4NUG`
};
export default ___CSS_LOADER_EXPORT___;
