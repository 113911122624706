import map from 'lodash/map';
export function fromDTO(dto) {
  var _dto$businessOverride;
  const businessIdToValue = (_dto$businessOverride = dto.businessOverrides) === null || _dto$businessOverride === void 0 ? void 0 : _dto$businessOverride.reduce((acc, cur) => {
    acc[cur.businessId] = cur.value;
    return acc;
  }, {});
  return {
    id: dto.id,
    key: dto.key,
    name: dto.name,
    deleted: dto.deleted,
    value: dto.defaultValue,
    businessOverrides: businessIdToValue || {}
  };
}
export function toDTO(featureFlag) {
  const businessOverridesArray = map(featureFlag.businessOverrides, (value, businessId) => ({
    businessId,
    value
  }));
  return {
    id: featureFlag.id,
    key: featureFlag.key,
    name: featureFlag.name,
    deleted: featureFlag.deleted,
    updatedOn: featureFlag.updatedOn,
    defaultValue: featureFlag.value,
    businessOverrides: businessOverridesArray
  };
}