// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WgESQ{fill:none;stroke:#fff;stroke-opacity:1;stroke-width:14;animation-timing-function:linear;animation-iteration-count:infinite;stroke-dasharray:160}.PIFxH{animation-name:ECibJ}.qkl7N{animation-name:tCtTE}.hIvzm{animation-name:albo6}@keyframes ECibJ{0%{stroke-dashoffset:160}100%{stroke-dashoffset:-160}}@keyframes tCtTE{0%{stroke-dashoffset:160}10%{stroke-dashoffset:160}100%{stroke-dashoffset:-120}}@keyframes albo6{0%{stroke-dashoffset:160}25%{stroke-dashoffset:160}100%{stroke-dashoffset:-80}}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"draw": `WgESQ`,
	"draw1": `PIFxH`,
	"brush1": `ECibJ`,
	"draw2": `qkl7N`,
	"brush2": `tCtTE`,
	"draw3": `hIvzm`,
	"brush3": `albo6`
};
export default ___CSS_LOADER_EXPORT___;
