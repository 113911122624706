import { useGlobalContext } from 'seagull';
import { useStoreActions } from "../store/store-context";
export function useAnchorAdmin() {
  const {
    api
  } = useStoreActions();
  const globalContext = useGlobalContext();
  return businessId => {
    api.sendRequest({
      method: 'post',
      url: "/businesses/".concat(businessId, "/anchor-admin-login"),
      withCredentials: true
    }).then(() => {
      window.open("https://".concat(window._authDomain, "/home"));
    }, e => {
      var _e$message;
      globalContext.showErrorToast((_e$message = e === null || e === void 0 ? void 0 : e.message) !== null && _e$message !== void 0 ? _e$message : 'Failed to login as Anchor admin, try again later');
    });
  };
}