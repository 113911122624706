import { getRequiredValidator } from "./required";
import { getMaxLengthValidationError, getMaxLengthValidator, getNoHtmlPatternError, noHtmlPatternValidator } from "./rawValidators";
const maxLength = 80;
export const defaultCompanyNameValueName = 'Company name';

/** true means there's no error. false means there's an error. string means there's an error and gives the error-message. */
export const getCompanyNameValidator = function getCompanyNameValidator() {
  let valueName = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : defaultCompanyNameValueName;
  let required = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  return value => {
    const requiredError = getRequiredValidator(required)(value);
    if (requiredError !== true) {
      return requiredError;
    }
    if (!getMaxLengthValidator(maxLength)(value)) {
      return getMaxLengthValidationError(valueName, maxLength);
    }
    if (!noHtmlPatternValidator(value)) {
      return getNoHtmlPatternError(valueName);
    }
    return true;
  };
};

/** For react-hook-form library. */
export const companyNameValidation = function companyNameValidation(valueName) {
  let {
    required
  } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return {
    validate: {
      company: getCompanyNameValidator(valueName, required)
    },
    dataAttr: {
      'data-validation': 'company'
    }
  };
};