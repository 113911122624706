import { isValidPhoneNumber } from 'libphonenumber-js';
import { getRequiredValidator } from "./required";
export const getPhoneNumberError = valueName => "".concat(valueName, " is invalid");
export const usPhoneNumberError = "invalid US phone number";
const validUSPhoneRegex = new RegExp(/^\+1\d{10}$/);

/** true means there's no error. false means there's an error. string means there's an error and gives the error-message. */
export const getPhoneNumberValidator = function getPhoneNumberValidator() {
  let valueName = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'Phone number';
  let required = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  return value => {
    const requiredError = getRequiredValidator(required)(value);
    if (requiredError !== true) {
      return requiredError;
    }
    const isEmptyPhoneNumber = !value || value === '';
    const hasSpaces = typeof value === 'string' && value.split('').includes(' ');
    const validPhoneNumber = typeof value === 'string' && isValidPhoneNumber(value) && !hasSpaces;
    return isEmptyPhoneNumber || validPhoneNumber || getPhoneNumberError(valueName);
  };
};

/** For react-hook-form library. */
export const phoneNumberValidation = function phoneNumberValidation(valueName) {
  let {
    required = false
  } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return {
    validate: {
      phoneNumber: getPhoneNumberValidator(valueName, required)
    },
    dataAttr: {
      'data-validation': 'phone-number'
    }
  };
};
export const getUSPhoneNumberValidator = function getUSPhoneNumberValidator() {
  let required = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  return value => {
    const requiredError = getRequiredValidator(required)(value);
    if (requiredError !== true) {
      return requiredError;
    }
    const isEmptyPhoneNumber = !value || value === '';
    const validPhoneNumber = typeof value === 'string' && validUSPhoneRegex.test(value);
    return isEmptyPhoneNumber || validPhoneNumber || usPhoneNumberError;
  };
};

/** For react-hook-form library. */
export const usPhoneNumberValidation = function usPhoneNumberValidation() {
  let {
    required = false
  } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return {
    validate: {
      phoneNumber: getUSPhoneNumberValidator(required)
    },
    dataAttr: {
      'data-validation': 'us-phone-number'
    }
  };
};