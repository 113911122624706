// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.k0rYZ{border-radius:0}.yr1PN{display:flex;flex-direction:row;align-items:center;gap:8px}.MRvnG{display:flex;gap:16px;align-items:center}.F81vj{display:flex;flex-direction:column;gap:16px}.w9_F4{width:40px;height:40px;flex-shrink:0;border:1px solid #fff;border-radius:50%;overflow:hidden}.PaMu5{background-color:#5c7fff;display:flex;justify-content:center;align-items:center;width:100%;height:100%}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `k0rYZ`,
	"header": `yr1PN`,
	"footer": `MRvnG`,
	"footerContainer": `F81vj`,
	"profilePicContainer": `w9_F4`,
	"profileDefault": `PaMu5`
};
export default ___CSS_LOADER_EXPORT___;
