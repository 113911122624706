// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bNnEG{position:relative;--border-color: #282863;--text-color: #696992;--label-color: #696992}.bNnEG:focus-within{--border-color: #6942f8;--label-color: #6942f8}.bNnEG.jsFgy{--border-color: #e86161;--label-color: #e86161}.bNnEG.KMmDv{--text-color: #9393b1;--label-color: #9393b1;--border-color: #bfbfd0}.Tpof1{display:grid;align-items:center}.Tpof1 *{font-family:"Lufga-Regular";font-weight:400}.Tpof1 .ktB6J.ktB6J{font-size:var(--font-size-p1, 16px);line-height:var(--line-height-p1, 24px);letter-spacing:.015em;block-size:46px;border-radius:4px;border:1px solid var(--border-color);color:#696992;inline-size:100%;background:none;box-shadow:none;outline:2px solid transparent}.Tpof1 .ktB6J.ktB6J:disabled{color:#9393b1;border-color:var(--border-color)}.Tpof1:hover .ktB6J.ktB6J:not(:disabled){border-width:2px}.Tpof1:focus-within .ktB6J.ktB6J,.Tpof1 .ktB6J.ktB6J:focus{border:2px solid var(--border-color);box-shadow:none}.Tpof1 .edOs3.edOs3{padding:0;border:none;padding-inline-start:5px}.KMmDv .Tpof1 .edOs3.edOs3{pointer-events:none}.qrlgN,.hLHEq{grid-area:1/1}.qrlgN{z-index:1;transition-duration:200ms;transition-timing-function:cubic-bezier(0, 0, 0.2, 1);transition-property:color,transform;transform-origin:left center;transform:translateX(52px);padding-inline:8px}.GfKl_{padding-inline:8px;color:var(--label-color)}.Tpof1:focus-within .qrlgN,.Tpof1.Oy2qT .qrlgN{transform:translate(3px, -108%) scale(0.75)}.Tpof1:focus-within .GfKl_,.Tpof1.Oy2qT .GfKl_{background-color:#fcfcfd}.Tpof1:focus-within .qrlgN{color:#6942f8}.XF4qQ{position:absolute;left:0;bottom:0;margin-inline-start:12px;transform:translateY(100%)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"blue45": `#fcfcff`,
	"blue48": `#f5f7fd`,
	"blue50": `#f3f5ff`,
	"blue300": `#dae0ff`,
	"blue500": `#c0caff`,
	"blue600": `#a1aef5`,
	"blue700": `#8296ff`,
	"blue800": `#6176e7`,
	"dark50": `#fcfcfd`,
	"dark70": `#f4f4f7`,
	"dark100": `#eaeaef`,
	"dark120": `#e4e4ec`,
	"dark200": `#d4d4e0`,
	"dark300": `#bfbfd0`,
	"dark400": `#a9a9c1`,
	"dark500": `#9393b1`,
	"dark600": `#7e7ea1`,
	"dark700": `#696992`,
	"dark800": `#535382`,
	"dark900": `#282863`,
	"green45": `#eff6fa`,
	"green50": `#f3fcfa`,
	"green300": `#b6ebdf`,
	"green500": `#86ddca`,
	"green700": `#56d0b5`,
	"green800": `#36b89b`,
	"green900": `#089475`,
	"orange50": `#fdf2dc`,
	"orange300": `#fadea8`,
	"orange500": `#f9d797`,
	"orange700": `#f7ca75`,
	"orange800": `#f5bd52`,
	"orange900": `#dd9306`,
	"purple50": `#e1d9fe`,
	"purple300": `#c3b3fc`,
	"purple500": `#a58efb`,
	"purple700": `#8768f9`,
	"purple800": `#6942f8`,
	"purple900": `#4520cc`,
	"red200": `#fdf0f0`,
	"red300": `#fee3e3`,
	"red500": `#f9d5d5`,
	"red700": `#f3aaaa`,
	"red900": `#eb7272`,
	"red950": `#e86161`,
	"red970": `#dd4c4c`,
	"white": `#fff`,
	"defaultTextStandard": `#282863`,
	"defaultTextSecondary": `#696992`,
	"defaultButtonPrimary": `#6942f8`,
	"buttonPrimary": `var(--button-primary, #6942f8)`,
	"buttonPrimaryDark": `var(--button-primary-dark, #4520cc)`,
	"buttonDisabled": `var(--button-disabled, #bfbfd0)`,
	"buttonDisabledDark": `var(--button-disabled-dark, #a9a9c1)`,
	"defaultButtonDisabled": `#bfbfd0`,
	"defaultStepperCompleted": `#089475`,
	"defaultStepperIncomplete": `#d4d4e0`,
	"defaultButtonSecondary": `#fcfcfd`,
	"defaultBgStandard": `#f3f5ff`,
	"defaultBgLight": `#fff`,
	"fontFamilyLight": `"Lufga-Regular"`,
	"fontFamilyNormal": `"Lufga-Medium"`,
	"fontFamilyBold": `"Lufga-SemiBold"`,
	"fontSizeH1": `var(--font-size-h1, 48px)`,
	"fontSizeH2": `var(--font-size-h2, 40px)`,
	"fontSizeH3": `var(--font-size-h3, 33px)`,
	"fontSizeH4": `var(--font-size-h4, 28px)`,
	"fontSizeH5": `var(--font-size-h5, 22px)`,
	"fontSizeH6": `var(--font-size-h6, 20px)`,
	"fontSizeP1": `var(--font-size-p1, 16px)`,
	"fontSizeP2": `var(--font-size-p2, 14px)`,
	"fontSizeP3": `var(--font-size-p3, 12px)`,
	"lineHeightH1": `var(--line-height-h1, 64px)`,
	"lineHeightH2": `var(--line-height-h2, 56px)`,
	"lineHeightH3": `var(--line-height-h3, 40px)`,
	"lineHeightH4": `var(--line-height-h4, 40px)`,
	"lineHeightH5": `var(--line-height-h5, 32px)`,
	"lineHeightH6": `var(--line-height-h6, 35px)`,
	"lineHeightP1": `var(--line-height-p1, 24px)`,
	"lineHeightP2": `var(--line-height-p2, 18px)`,
	"lineHeightP3": `var(--line-height-p3, 16px)`,
	"root": `bNnEG`,
	"error": `jsFgy`,
	"disabled": `KMmDv`,
	"container": `Tpof1`,
	"input": `ktB6J`,
	"flagsButton": `edOs3`,
	"label": `qrlgN`,
	"inputContainer": `hLHEq`,
	"labelInner": `GfKl_`,
	"liftedLabel": `Oy2qT`,
	"helperText": `XF4qQ`
};
export default ___CSS_LOADER_EXPORT___;
