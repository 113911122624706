// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.aTp9v{margin-bottom:16px}.b2maf{display:flex;align-items:center;justify-content:center}.bfz4v{height:300px;display:flex;align-items:center;justify-content:center}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchbox": `aTp9v`,
	"defaultValue": `b2maf`,
	"loader": `bfz4v`
};
export default ___CSS_LOADER_EXPORT___;
