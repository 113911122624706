const charset = '1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
export function generateUniqueId(prefix) {
  return "".concat(prefix, "-").concat(getTimePart(Date.now()), "-").concat(getRandomPart());
}
function getRandomPart() {
  return Array.from(crypto.getRandomValues(new Uint8Array(16))).map(i => charset[i % charset.length]).join('');
}
function getTimePart(timestamp) {
  let timePart = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  if (timestamp === 0) return timePart;
  const newTimePart = timePart.concat(charset[timestamp % charset.length]);
  const newTimestamp = Math.floor(timestamp / charset.length);
  return getTimePart(newTimestamp, newTimePart);
}