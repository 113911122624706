// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tk6yH{width:100%;margin:0 auto;position:relative}.jzE8_,.vb0Q9{position:absolute;z-index:2;top:40%}.jzE8_.disabled,.vb0Q9.disabled{display:none}.jzE8_{right:15px}.vb0Q9{left:15px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"glider-contain": `tk6yH`,
	"glider-next": `jzE8_`,
	"glider-prev": `vb0Q9`
};
export default ___CSS_LOADER_EXPORT___;
