const _excluded = ["isOpen", "prevIsOpen", "content", "onClose"];
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var n = Object.getOwnPropertySymbols(e); for (r = 0; r < n.length; r++) o = n[r], t.indexOf(o) >= 0 || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (e.indexOf(n) >= 0) continue; t[n] = r[n]; } return t; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { useEffect, useRef } from 'react';
import { usePrevious } from "../../utils/usePrevious";
import { useStateInRef } from "../../utils/useStateInRef";
import { useGlobalContext } from "../GlobalContext";
/** Renders a modal whose open state is controlled by props.  */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function DeclarativeModal(props) {
  const {
    isOpen
  } = props;
  const globalContext = useGlobalContext();
  const prevIsOpen = usePrevious(isOpen);
  const getProps = useStateInRef(_objectSpread(_objectSpread({}, props), {}, {
    prevIsOpen
  }));
  const dismissModalRef = useRef(() => {});
  useEffect(() => {
    const _getProps = getProps(),
      {
        isOpen,
        prevIsOpen,
        content: _content,
        onClose
      } = _getProps,
      rest = _objectWithoutProperties(_getProps, _excluded);
    if (isOpen && !prevIsOpen) {
      let wasClosed = false;
      globalContext.showModal(_objectSpread(_objectSpread({}, rest), {}, {
        content: p => {
          dismissModalRef.current = function () {
            return p.dismissModal(...arguments);
          };
          return _content(_objectSpread(_objectSpread({}, p), {}, {
            dismissModal: dismissModalRef.current
          }));
        }
      })).then(() => {
        if (wasClosed) return;
        wasClosed = true;
        dismissModalRef.current = () => {};
        onClose && onClose();
      });
    }
    if (prevIsOpen && !isOpen) {
      dismissModalRef.current();
    }
  }, [isOpen]);
  useEffect(() => {
    return () => {
      dismissModalRef.current();
    };
  }, []);
  return null;
}