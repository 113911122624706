import { moneyModel } from "./..";
const getAmount = value => moneyModel.isMoney(value) ? moneyModel.amount(value) : value;
const _mul = (value, quantity) => make(value.mul(getAmount(quantity)));
const _div = (value, quantity) => make(value.div(getAmount(quantity)));
const _add = (value1, value2) => make(value1.add(getAmount(value2)));
const _sub = (value1, value2) => make(value1.sub(getAmount(value2)));
const _abs = value => make(value.abs());
const _neg = value => make(value.neg());
const _toFixed = (value, decimalPoint) => value.toFixed(decimalPoint);
const _isZero = value => value.isZero();
const _isNegative = value => value.isNegative();
const _isInteger = value => value.isInteger();
const _toDecimalPlaces = (value, dp) => make(value.toDecimalPlaces(dp));
export function make(amount) {
  return {
    amount,
    mul: quantity => _mul(amount, quantity),
    div: quantity => _div(amount, quantity),
    add: amount2 => _add(amount, amount2),
    sub: amount2 => _sub(amount, amount2),
    abs: () => _abs(amount),
    neg: () => _neg(amount),
    toFixed: decimalPoint => _toFixed(amount, decimalPoint),
    isZero: () => _isZero(amount),
    isNegative: () => _isNegative(amount),
    isInteger: () => _isInteger(amount),
    toDecimalPlaces: dp => _toDecimalPlaces(amount, dp)
  };
}