import { useStoreActions } from "../store/store-context";
export function useUpdateBusinessClassification() {
  const {
    api
  } = useStoreActions();
  return function updateBusinessClassification(businessId, classification, enabled) {
    return api.sendRequest({
      method: 'post',
      url: "/businesses/".concat(businessId, "/update/classification"),
      data: {
        classification,
        enabled
      }
    });
  };
}