import { getMaxLengthValidationError, getMaxLengthValidator, getMinLengthValidationError, getMinLengthValidator } from "./rawValidators";
import { getRequiredValidator } from "./required";
const maxLength = 30;
const minLength = 2;
export const getNamePatternErrorMsg = valueName => "".concat(valueName, " may not contain: =<>");

/** true means there's no error. false means there's an error. string means there's an error and gives the error-message. */
export const getNameValidator = function getNameValidator() {
  let valueName = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'Name';
  let required = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  return value => {
    const requiredError = getRequiredValidator(required)(value);
    if (requiredError !== true) {
      return requiredError;
    }
    if (!getMaxLengthValidator(maxLength)(value)) {
      return valueName ? getMaxLengthValidationError(valueName, maxLength) : false;
    }
    if (!getMinLengthValidator(minLength)(value)) {
      return valueName ? getMinLengthValidationError(valueName, minLength) : false;
    }
    if (!(typeof value === 'string' && value.match(/[=<>]/) == null)) {
      return valueName ? getNamePatternErrorMsg(valueName) : false;
    }
    return true;
  };
};

/** For react-hook-form library. */
export const nameValidation = function nameValidation(valueName) {
  let {
    required
  } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return {
    validate: {
      name: getNameValidator(valueName, required)
    },
    dataAttr: {
      'data-validation': 'name'
    }
  };
};