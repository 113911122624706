// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.J12aq{cursor:pointer;display:flex;align-items:center;gap:8px}.J12aq:hover:not(.AqzGG) .dqgPc{border-color:#282863}.J12aq.Ilbqc{display:inline-flex}.J12aq.AqzGG{cursor:default}.J12aq.AqzGG .dqgPc{color:#9393b1;border-color:#bfbfd0}.J12aq.AqzGG .Tq4hw,.J12aq.AqzGG .fmOeW{background:#9393b1;color:#fff}.J12aq .FehWe:focus-within{outline:2px solid #8296ff}.FehWe{height:20px;width:20px;position:relative;display:inline-block;border-radius:4px}.sg-focus-disabled .J12aq .FehWe:focus-within{outline:0}.yU3bw{top:0;left:0;width:100%;cursor:inherit;height:100%;margin:0;opacity:0;padding:0;z-index:1;position:absolute}.lr0Pf{border-radius:4px;height:100%;width:20px}.dqgPc{box-sizing:border-box;border:1px solid #696992}.dqgPc.bolHJ{border:1px solid #e86161}.fmOeW{background:#6942f8;color:#fcfcfd}.Tq4hw{background:#6942f8;color:#fcfcfd}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"blue45": `#fcfcff`,
	"blue48": `#f5f7fd`,
	"blue50": `#f3f5ff`,
	"blue300": `#dae0ff`,
	"blue500": `#c0caff`,
	"blue600": `#a1aef5`,
	"blue700": `#8296ff`,
	"blue800": `#6176e7`,
	"dark50": `#fcfcfd`,
	"dark70": `#f4f4f7`,
	"dark100": `#eaeaef`,
	"dark120": `#e4e4ec`,
	"dark200": `#d4d4e0`,
	"dark300": `#bfbfd0`,
	"dark400": `#a9a9c1`,
	"dark500": `#9393b1`,
	"dark600": `#7e7ea1`,
	"dark700": `#696992`,
	"dark800": `#535382`,
	"dark900": `#282863`,
	"green45": `#eff6fa`,
	"green50": `#f3fcfa`,
	"green300": `#b6ebdf`,
	"green500": `#86ddca`,
	"green700": `#56d0b5`,
	"green800": `#36b89b`,
	"green900": `#089475`,
	"orange50": `#fdf2dc`,
	"orange300": `#fadea8`,
	"orange500": `#f9d797`,
	"orange700": `#f7ca75`,
	"orange800": `#f5bd52`,
	"orange900": `#dd9306`,
	"purple50": `#e1d9fe`,
	"purple300": `#c3b3fc`,
	"purple500": `#a58efb`,
	"purple700": `#8768f9`,
	"purple800": `#6942f8`,
	"purple900": `#4520cc`,
	"red200": `#fdf0f0`,
	"red300": `#fee3e3`,
	"red500": `#f9d5d5`,
	"red700": `#f3aaaa`,
	"red900": `#eb7272`,
	"red950": `#e86161`,
	"red970": `#dd4c4c`,
	"white": `#fff`,
	"defaultTextStandard": `#282863`,
	"defaultTextSecondary": `#696992`,
	"defaultButtonPrimary": `#6942f8`,
	"buttonPrimary": `var(--button-primary, #6942f8)`,
	"buttonPrimaryDark": `var(--button-primary-dark, #4520cc)`,
	"buttonDisabled": `var(--button-disabled, #bfbfd0)`,
	"buttonDisabledDark": `var(--button-disabled-dark, #a9a9c1)`,
	"defaultButtonDisabled": `#bfbfd0`,
	"defaultStepperCompleted": `#089475`,
	"defaultStepperIncomplete": `#d4d4e0`,
	"defaultButtonSecondary": `#fcfcfd`,
	"defaultBgStandard": `#f3f5ff`,
	"defaultBgLight": `#fff`,
	"FOCUS_DISABLED_CLASS": `sg-focus-disabled`,
	"root": `J12aq`,
	"disabled": `AqzGG`,
	"unchecked": `dqgPc`,
	"inline": `Ilbqc`,
	"checked": `Tq4hw`,
	"partiallyChecked": `fmOeW`,
	"checkbox": `FehWe`,
	"input": `yU3bw`,
	"checkboxIndicator": `lr0Pf`,
	"error": `bolHJ`
};
export default ___CSS_LOADER_EXPORT___;
