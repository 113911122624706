import { useEffect, useState } from 'react';
import { useStoreActions } from "../store/store-context";
export function useMeQuery() {
  const {
    api
  } = useStoreActions();
  const [me, setMe] = useState(defaultMe);
  useEffect(() => {
    api.sendRequest({
      method: 'get',
      url: '/me'
    }).then(response => {
      setMe(response.data);
    }).catch(error => {
      console.error("can get user permissions: ".concat(error));
    });
  }, []);
  return {
    permissions: me.permissions
  };
}
const defaultMe = {
  permissions: {
    impersonate: false,
    anchorAdminLogin: false
  }
};