// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qUXS9{margin-bottom:16px}.t8jDP{display:flex;align-items:center;gap:16px}.lOeWC{width:340px}.KXhDw{padding:24px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paragraph": `qUXS9`,
	"fieldWithLabel": `t8jDP`,
	"label": `lOeWC`,
	"content": `KXhDw`
};
export default ___CSS_LOADER_EXPORT___;
