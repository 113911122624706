// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sRQBp{padding:16px 20px}.Dt26K{position:sticky;top:0}.KCBZ7{min-height:30vh;min-width:1000px}.LuRIf{margin-left:4px}.HZh2r{display:flex;align-items:center}.v3IXg{height:300px;display:flex;align-items:center;justify-content:center}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalHeader": `sRQBp`,
	"tableHeader": `Dt26K`,
	"modal": `KCBZ7`,
	"businessOverrideFalse": `LuRIf`,
	"overrideContainer": `HZh2r`,
	"loaderContainer": `v3IXg`
};
export default ___CSS_LOADER_EXPORT___;
