import { getRequiredValidator } from "./required";
import { getMaxLengthValidationError, getMaxLengthValidator, getNoHtmlPatternError, noHtmlPatternValidator } from "./rawValidators";
const maxLength = 120;
const maxLengthValidator = getMaxLengthValidator(maxLength);

/** true means there's no error. false means there's an error. string means there's an error and gives the error-message. */
export function getTitleValidator() {
  let valueName = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'Name';
  let required = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  const requiredValidator = getRequiredValidator(required);
  const maxLengthValidationError = getMaxLengthValidationError(valueName, maxLength);
  const patternError = getNoHtmlPatternError(valueName);
  return value => {
    const requiredError = requiredValidator(value);
    if (requiredError !== true) {
      return requiredError;
    }
    if (!maxLengthValidator(value)) {
      return maxLengthValidationError;
    }
    if (!noHtmlPatternValidator(value)) {
      return patternError;
    }
    return true;
  };
}

/** For react-hook-form library. */
export const titleValidation = function titleValidation(valueName) {
  let {
    required
  } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return {
    validate: {
      title: getTitleValidator(valueName, required)
    },
    dataAttr: {
      'data-validation': 'title'
    }
  };
};