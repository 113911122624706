import focusManagement from "./foundations/focus-management.module.scss";

/**
 * This class is put on the body element whenever a mouse is clicked. It disables
 * focus behaviour for seagull components.
 */
export const FOCUS_DISABLED_CLASS = focusManagement.FOCUS_DISABLED_CLASS;

/**
 * Adds a class to the document body whenever the user clicks on a mouse and removes
 * it when the user presses the tab key. This class is used by components to prevent
 * the focus indicator from showing when clicking on the mouse but to show the focus
 * indicator when tabing through elements.
 */
export function onlyShowFocusOnTabs() {
  let hasFocusDisabledClass = true;
  document.body.classList.add(FOCUS_DISABLED_CLASS);
  document.addEventListener('mousedown', () => {
    if (!hasFocusDisabledClass) {
      hasFocusDisabledClass = true;
      document.body.classList.add(FOCUS_DISABLED_CLASS);
    }
  }, true);
  document.addEventListener('keydown', event => {
    if (event.key === 'Tab' && hasFocusDisabledClass) {
      hasFocusDisabledClass = false;
      document.body.classList.remove(FOCUS_DISABLED_CLASS);
    }
  }, true);
}