function makeDefaultLoadableContent() {
  return {
    data: null,
    loading: false,
    error: null,
    lastSuccessfulFetch: null
  };
}
export function getDefaultInitialState() {
  return {
    userInfo: null,
    vendors: makeDefaultLoadableContent()
  };
}