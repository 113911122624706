export const ledgerTypesTextMap = {
  init: 'Init',
  payment_initiated: 'Payment Initiated',
  payment_succeeded: 'Payment Succeeded',
  payment_returned: 'Payment Returned',
  payment_return_disputed: 'Payment Return Disputed',
  payment_late_return_disputed: 'Payment Late Return Disputed',
  payment_late_return: 'Payment Late Return',
  payment_fee: 'Payment Fee',
  refund_initiated: 'Refund Initiated',
  refund_succeeded: 'Refund Succeeded',
  dispute_fee: 'Dispute Fee',
  payout_initiated: 'Payout Initiated',
  payout_succeeded: 'Payout Succeeded',
  payout_returned: 'Payout Returned',
  payout_late_return: 'Payout Late Return',
  withdrawal_initiated: 'Withdrawal Initiated',
  withdrawal_succeeded: 'Withdrawal Succeeded',
  withdrawal_returned: 'Withdrawal Returned',
  withdrawal_late_return: 'Withdrawal Late Return'
};