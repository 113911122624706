// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NpQPK{height:100%;display:flex;gap:16px;padding:12px 48px 0 48px;box-sizing:border-box}.uVSPJ{width:50%;max-width:50%;min-width:50%}.YQbSz{height:100%;display:flex;flex-direction:column}.us5DF{padding-top:16px;flex:1;height:100%;overflow:auto}.ZwPG2{height:100%;overflow:auto}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `NpQPK`,
	"pane": `uVSPJ`,
	"leftPane": `YQbSz`,
	"leftContent": `us5DF`,
	"rightPane": `ZwPG2`
};
export default ___CSS_LOADER_EXPORT___;
