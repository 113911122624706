export const getRequiredValidator = required => value => required === false ? true : requiredValidator(value) || (typeof required === 'string' ? required : false);

/** validates required with trim */
export const requiredValidator = value => value != null && (typeof value !== 'string' || !!value.trim());

/** For react-hook-form library. */
export const requiredValidation = required => ({
  validate: {
    required: getRequiredValidator(required)
  }
});