// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.zwub3{margin-bottom:8px;display:flex;gap:8px}.KoHUH{min-width:fit-content}.xlHkx{margin-bottom:6px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filtersRow": `zwub3`,
	"tableRow": `KoHUH`,
	"relatedId": `xlHkx`
};
export default ___CSS_LOADER_EXPORT___;
