// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Qk5FS{display:flex;flex-direction:column;margin-left:20px;background-color:#fcfcfd}.aEgyi{display:flex;width:40%;min-width:200px;justify-content:space-between;align-items:center}.NyyZh{width:75%}.Gy8HV{flex:1}.MELvK{display:flex;flex-direction:column;flex:5}.tVFl6{top:0;position:sticky;z-index:9999}.LuPV0{display:flex;padding:24px 0 16px 0;background-color:#fcfcfd}.q59eO{display:flex;height:100%;width:100%;align-items:center;justify-content:center}.cBlSp{margin-left:16px}.pmwmg{width:100%;display:flex;flex-direction:column;margin-top:40px;align-items:center}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"editModalBody": `Qk5FS`,
	"detailsRow": `aEgyi`,
	"nameField": `NyyZh`,
	"detailsContainer": `Gy8HV`,
	"overridesContainer": `MELvK`,
	"overridesTitle": `tVFl6`,
	"title": `LuPV0`,
	"loader": `q59eO`,
	"addButton": `cBlSp`,
	"vendorsErrorContainer": `pmwmg`
};
export default ___CSS_LOADER_EXPORT___;
