// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AOe4F{display:flex;justify-content:space-between;gap:8px}.C7Xu5{display:grid;grid-template-areas:"backButton header" ". subheader"}.EoXlD{column-gap:24px}.bgTPZ{display:flex;align-items:center;gap:8px;grid-area:header}.MSxTv{max-inline-size:70ch;grid-area:subheader}.IePfF{grid-area:backButton}.gJZQ6{display:flex;align-items:center;gap:12px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `AOe4F`,
	"leadingArea": `C7Xu5`,
	"backButtonShown": `EoXlD`,
	"header": `bgTPZ`,
	"subheader": `MSxTv`,
	"backButton": `IePfF`,
	"actions": `gJZQ6`
};
export default ___CSS_LOADER_EXPORT___;
