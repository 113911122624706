export const getMaxLengthValidator = maxLength => value => !value || typeof value !== 'string' || value.length <= maxLength;
export const getMaxLengthValidationError = (name, maxLength) => "".concat(name, " can be up to ").concat(maxLength, " characters");
export const getMinLengthValidator = minLength => value => {
  return !value || typeof value !== 'string' || value.trim().length >= minLength;
};
export const getMinLengthValidationError = (name, minLength) => "".concat(name, " must be at least ").concat(minLength, " characters");
export const getPatternValidator = pattern => value => typeof value !== 'string' || !value || value.match(pattern) != null;

/** Validates no html has entered. */
export const noHtmlPatternValidator = getPatternValidator(/^((?!<\/?[a-zA-Z][\s\S]*>)[\s\S])*$/);
export const getNoHtmlPatternError = valueName => "".concat(valueName ? "".concat(valueName, " may") : 'May', " include only these special characters: _%+:;#()[]$*&,.'-");
export const zipCodeUsPatternValidator = getPatternValidator(/(^\d{5}$)|(^\d{5}-\d{4}$)/);
export const getGenericError = valueName => "".concat(valueName, " is invalid");