// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TNIbA{display:grid;place-content:center;block-size:100vh}.gpjgZ{text-align:center}.gpjgZ h1{font-size:2rem;font-family:"Signpainter",sans-serif;color:#6942f8}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `TNIbA`,
	"buttonWrapper": `gpjgZ`
};
export default ___CSS_LOADER_EXPORT___;
