/** a partial validation to force two digits while typing a percentage */
export function isValidPercentageWithPrecisionOf2(value) {
  const [beforeDecimal, afterDecimal] = value.split('.');
  if (beforeDecimal === '100') {
    return !afterDecimal;
  } else {
    return !((beforeDecimal === null || beforeDecimal === void 0 ? void 0 : beforeDecimal.length) > 2 || (afterDecimal === null || afterDecimal === void 0 ? void 0 : afterDecimal.length) > 2);
  }
}

/** a partial validation to force two digits while typing a number */
export function isValidNumberWithPrecisionOf2(value) {
  const [, afterDecimal] = value.split('.');
  return !afterDecimal || afterDecimal.length < 3;
}