// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ``, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mobileMaxWidth": `560px`,
	"tabletMinWidth": `561px`,
	"tabletMaxWidth": `1024px`,
	"desktopMinWidth": `1025px`,
	"smallScreenMaxWidth": `1400px`,
	"largeScreenMinWidth": `1401px`
};
export default ___CSS_LOADER_EXPORT___;
