export const routes = {
  home: '/home',
  users: '/users',
  user: userId => "/users/".concat(userId),
  businesses: '/businesses',
  business: businessId => "/businesses/".concat(businessId),
  relationships: '/relationships',
  relationship: relationshipId => "/relationships/".concat(relationshipId),
  featureFlags: '/feature-flags',
  allowlist: '/allowlist',
  editFeatureFlag: function editFeatureFlag() {
    let featureFlagId = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : ':featureFlag?';
    return "/feature-flags/".concat(featureFlagId);
  },
  backfill: '/backfill'
};