// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._qGvm{display:flex;align-items:center}.V83bx{margin-right:24px}.cixpR{display:flex;flex-direction:column;gap:24px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `_qGvm`,
	"entityName": `V83bx`,
	"content": `cixpR`
};
export default ___CSS_LOADER_EXPORT___;
