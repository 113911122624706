// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes SOU5K{0%{opacity:0;transform:translateY(32px)}100%{opacity:1;transform:translateY(0)}}@media not print{.Adebl{height:100%;overflow:auto}.Adebl.Y4mL5{filter:blur(3px)}}.YLz4t{--icon-color: var(--white);position:fixed;top:0;left:0;width:100%;height:100%;display:flex;align-items:center;justify-content:center;z-index:1400}.YLz4t>.EUt1l{width:inherit;height:inherit;background-color:#000;opacity:.3;position:absolute}.AHzyp{-webkit-tap-highlight-color:transparent}.l3fYU{background-color:rgba(0,0,0,.5)}.ztqNv{display:flex;align-items:center;justify-content:center;height:100%}.ztqNv>:last-child{z-index:1}.AHzyp{position:absolute;top:0;height:100%;left:0;width:100%}.PttHj{position:fixed;top:48px;left:50%;transform:translateX(-50%);width:min-content;height:min-content;z-index:1350}.PttHj>.V92Xp{margin-bottom:8px;animation:Rr2SD linear forwards;animation-duration:var(--toastDuration, 4.9s)}.PttHj>.V92Xp:last-of-type{margin-bottom:0}@keyframes Rr2SD{0%{opacity:0;transform:translateY(16px);max-height:48px}5%{opacity:1;transform:translateY(0px);max-height:48px}95%{opacity:1;transform:translateY(0px);max-height:48px}100%{opacity:0;transform:translateY(-16px);max-height:0}}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"globalContext": `Adebl`,
	"blur": `Y4mL5`,
	"globalLoader": `YLz4t`,
	"overlay": `EUt1l`,
	"modalBackdrop": `AHzyp`,
	"modalBg": `l3fYU`,
	"modalContainer": `ztqNv`,
	"toastsContainer": `PttHj`,
	"toast": `V92Xp`,
	"toast-animation": `Rr2SD`,
	"modalanimation": `SOU5K`
};
export default ___CSS_LOADER_EXPORT___;
