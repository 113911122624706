import { getMaxLengthValidationError, getMaxLengthValidator, getPatternValidator } from "./rawValidators";
import { getRequiredValidator } from "./required";
export function isPoBox(str) {
  // https://www.oreilly.com/library/view/regular-expressions-cookbook/9781449327453/ch04s17.html
  return /^(?:Post(?:al)? (?:Office )?|P[.\- ]?O\.? )?Box\b/im.test(str);
}
const maxLength = 50;
const maxLengthValidator = getMaxLengthValidator(maxLength);
const streetNamePatternValidator = getPatternValidator(/^[a-zA-Z0-9 ,.()#'-]*$/);
export const getStreetNamePatternError = valueName => "".concat(valueName, " may include only these special characters: ,.()#'-");

/** true means there's no error. false means there's an error. string means there's an error and gives the error-message. */
export function getStreetNameValidator() {
  let valueName = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'Street name';
  let required = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  const requiredValidator = getRequiredValidator(required);
  const maxLengthValidationError = getMaxLengthValidationError(valueName, maxLength);
  return value => {
    const requiredError = requiredValidator(value);
    if (requiredError !== true) {
      return requiredError;
    }
    if (!maxLengthValidator(value)) {
      return maxLengthValidationError;
    }
    if (typeof value === 'string' && value && isPoBox(value)) {
      return 'PO boxes are not allowed';
    }
    if (!streetNamePatternValidator(value)) {
      return getStreetNamePatternError(valueName);
    }
    return true;
  };
}

/** For react-hook-form library. */
export const streetNameValidation = function streetNameValidation(valueName) {
  let {
    required
  } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return {
    validate: {
      streetName: getStreetNameValidator(valueName, required)
    },
    dataAttr: {
      'data-validation': 'street-name'
    }
  };
};