// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.YPxEo{display:inline-block}.uweck{border:0;margin:0;background:transparent;cursor:pointer;border-radius:50%;display:flex;align-items:center;justify-content:center}.uweck:disabled,.uweck.disabled{cursor:default;color:#bfbfd0}.uweck.Ww2w8{padding:0}.uweck.WFMCp{cursor:default}.pdjRG{width:40px;height:40px;padding:6px}.nPF2N{width:24px;height:24px;padding:6px}.wzcQt{width:16px;height:16px}.t4jwh{color:#696992}.t4jwh.Zn0zK{border:1px solid #696992}.t4jwh:hover:not(:disabled){color:#282863;background:#eaeaef}.t4jwh:hover:not(:disabled).Zn0zK{border-color:#282863}.faEqR{color:#e86161}.faEqR:hover:not(:disabled){color:#dd4c4c;background:#fdf0f0}.kTJfI{color:#6942f8}.kTJfI:hover:not(:disabled){color:#4520cc;background:#e1d9fe}.CY02k{color:#c3b3fc;border:1px solid #c3b3fc;background-color:#fff}.CY02k:not(.disabled):not(:disabled):hover{color:#4520cc;background:#e1d9fe}.CY02k:not(.disabled):not(:disabled):active{color:#4520cc;background-color:#c3b3fc}.ASPsY{color:var(--button-primary, #6942f8)}.ASPsY:hover:not(:disabled){color:var(--button-primary-dark, #4520cc)}.fZF1R{color:var(--text-secondary, #696992)}.fZF1R.Zn0zK{border:1px solid var(--text-secondary, #696992)}.fZF1R:hover:not(:disabled){color:var(--text-standard, #282863)}.fZF1R:hover:not(:disabled).Zn0zK{border-color:var(--text-standard, #282863)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"blue45": `#fcfcff`,
	"blue48": `#f5f7fd`,
	"blue50": `#f3f5ff`,
	"blue300": `#dae0ff`,
	"blue500": `#c0caff`,
	"blue600": `#a1aef5`,
	"blue700": `#8296ff`,
	"blue800": `#6176e7`,
	"dark50": `#fcfcfd`,
	"dark70": `#f4f4f7`,
	"dark100": `#eaeaef`,
	"dark120": `#e4e4ec`,
	"dark200": `#d4d4e0`,
	"dark300": `#bfbfd0`,
	"dark400": `#a9a9c1`,
	"dark500": `#9393b1`,
	"dark600": `#7e7ea1`,
	"dark700": `#696992`,
	"dark800": `#535382`,
	"dark900": `#282863`,
	"green45": `#eff6fa`,
	"green50": `#f3fcfa`,
	"green300": `#b6ebdf`,
	"green500": `#86ddca`,
	"green700": `#56d0b5`,
	"green800": `#36b89b`,
	"green900": `#089475`,
	"orange50": `#fdf2dc`,
	"orange300": `#fadea8`,
	"orange500": `#f9d797`,
	"orange700": `#f7ca75`,
	"orange800": `#f5bd52`,
	"orange900": `#dd9306`,
	"purple50": `#e1d9fe`,
	"purple300": `#c3b3fc`,
	"purple500": `#a58efb`,
	"purple700": `#8768f9`,
	"purple800": `#6942f8`,
	"purple900": `#4520cc`,
	"red200": `#fdf0f0`,
	"red300": `#fee3e3`,
	"red500": `#f9d5d5`,
	"red700": `#f3aaaa`,
	"red900": `#eb7272`,
	"red950": `#e86161`,
	"red970": `#dd4c4c`,
	"white": `#fff`,
	"defaultTextStandard": `#282863`,
	"defaultTextSecondary": `#696992`,
	"defaultButtonPrimary": `#6942f8`,
	"buttonPrimary": `var(--button-primary, #6942f8)`,
	"buttonPrimaryDark": `var(--button-primary-dark, #4520cc)`,
	"buttonDisabled": `var(--button-disabled, #bfbfd0)`,
	"buttonDisabledDark": `var(--button-disabled-dark, #a9a9c1)`,
	"defaultButtonDisabled": `#bfbfd0`,
	"defaultStepperCompleted": `#089475`,
	"defaultStepperIncomplete": `#d4d4e0`,
	"defaultButtonSecondary": `#fcfcfd`,
	"defaultBgStandard": `#f3f5ff`,
	"defaultBgLight": `#fff`,
	"wrapper": `YPxEo`,
	"root": `uweck`,
	"noPadding": `Ww2w8`,
	"cursorDefault": `WFMCp`,
	"mediumSize": `pdjRG`,
	"smallSize": `nPF2N`,
	"xsmallSize": `wzcQt`,
	"standardSkin": `t4jwh`,
	"bordered": `Zn0zK`,
	"errorSkin": `faEqR`,
	"primarySkin": `kTJfI`,
	"primaryOpaqueSkin": `CY02k`,
	"primaryTransparentSkin": `ASPsY`,
	"transparentSkin": `fZF1R`
};
export default ___CSS_LOADER_EXPORT___;
