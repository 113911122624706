const _excluded = ["host", "headers"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var n = Object.getOwnPropertySymbols(e); for (r = 0; r < n.length; r++) o = n[r], t.indexOf(o) >= 0 || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (e.indexOf(n) >= 0) continue; t[n] = r[n]; } return t; }
import axios from 'axios';
import httpAdapter from 'axios/lib/adapters/http';
export default function (getState, sessionData) {
  const {
    mgmtHost,
    apiGatewayHost,
    boApiHost
  } = sessionData;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function sendRequest(options) {
    const {
        host = 'bo',
        headers = {}
      } = options,
      rest = _objectWithoutProperties(options, _excluded);
    return axios.request(_objectSpread(_objectSpread({}, rest), {}, {
      headers,
      adapter: httpAdapter,
      baseURL: host === 'api' ? apiGatewayHost : host === 'mgmt' ? mgmtHost : boApiHost,
      withCredentials: true
    })).catch(err => {
      var _err$response;
      if (((_err$response = err.response) === null || _err$response === void 0 ? void 0 : _err$response.status) === 401) {
        window.open('/');
      }
      throw err;
    });
  }
  return {
    sendRequest,
    async getVendors() {
      const res = await sendRequest({
        method: 'get',
        url: "/businesses/vendors"
      });
      return res.data;
    },
    async getFeatureFlags() {
      const res = await sendRequest({
        method: 'get',
        url: '/feature-flags'
      });
      res.data.sort((ff1, ff2) => ff1.key > ff2.key ? 1 : ff1.key < ff2.key ? -1 : 0);
      return res.data;
    },
    async updateFeatureFlag(featureFlag) {
      var _getState$userInfo;
      const data = featureFlag.updatedBy ? featureFlag : _objectSpread(_objectSpread({}, featureFlag), {}, {
        updatedBy: (_getState$userInfo = getState().userInfo) === null || _getState$userInfo === void 0 ? void 0 : _getState$userInfo.email
      });
      const res = await sendRequest({
        method: 'post',
        url: '/feature-flags',
        data
      });
      return res.data;
    },
    async googleLogin(id_token) {
      await sendRequest({
        method: 'post',
        url: '/oauth',
        data: {
          provider: 'google',
          id_token
        }
      });
    },
    async inviteMember(businessId, member) {
      const res = await sendRequest({
        method: 'POST',
        url: "/businesses/".concat(businessId, "/invite-member"),
        data: member
      });
      return res.data.id;
    },
    async backfillRelationships(relationshipIds) {
      await sendRequest({
        method: 'post',
        url: '/backfill/start/relationships',
        data: relationshipIds !== null && relationshipIds !== void 0 && relationshipIds.length ? {
          ids: relationshipIds
        } : {}
      });
    },
    async backfillUsers(userIds) {
      await sendRequest({
        method: 'post',
        url: '/backfill/start/users',
        data: userIds !== null && userIds !== void 0 && userIds.length ? {
          ids: userIds
        } : {}
      });
    },
    async backfillBusinesses(businessIds) {
      await sendRequest({
        method: 'post',
        url: '/backfill/start/businesses',
        data: businessIds !== null && businessIds !== void 0 && businessIds.length ? {
          ids: businessIds
        } : {}
      });
    },
    async backfillInvoices(invoiceIds) {
      await sendRequest({
        method: 'post',
        url: '/backfill/start/invoices',
        data: invoiceIds !== null && invoiceIds !== void 0 && invoiceIds.length ? {
          ids: invoiceIds
        } : {}
      });
    },
    async backfillQboInvoices(qboInvoiceIds) {
      await sendRequest({
        method: 'post',
        url: '/backfill/start/qbo-invoices',
        data: qboInvoiceIds !== null && qboInvoiceIds !== void 0 && qboInvoiceIds.length ? {
          ids: qboInvoiceIds
        } : {}
      });
    },
    async doNotMonitorBusinessSanctions(businessId, reason) {
      await sendRequest({
        method: 'post',
        url: "/businesses/".concat(businessId, "/update/sanction-status/do-not-monitor"),
        data: {
          reason
        }
      });
    },
    async doNotMonitorUserSanctions(userId, reason) {
      await sendRequest({
        method: 'post',
        url: "/users/".concat(userId, "/sanction-status/do-not-monitor"),
        data: {
          reason
        }
      });
    },
    async toggleColumnPaymentsStatus(businessId, status) {
      await sendRequest({
        method: 'post',
        url: "/businesses/".concat(businessId, "/update/column-payments-status"),
        data: {
          status
        }
      });
    }
  };
}