import { TableListProvider } from "./TableListProvider";
import { TableListHeader } from "./TableListHeader";
import { TableListRow } from "./TableListRow";
import { TableListStructure } from "./TableListStructure";
import InlineControl from "./InlineControl";
import styles from "./TableList.module.scss";
export const TableList = {
  Provider: TableListProvider,
  Header: TableListHeader,
  Row: TableListRow,
  Structure: TableListStructure,
  InlineControl,
  /** Modifying classes you can put on table list items to control their visibility. */
  classes: {
    /** Put this class on row items that should only be visible when the row is hovered. */
    showOnRowHover: styles.showOnRowHover,
    /** Put this class on row items that should hide themselves when the row is hovered. */
    hideOnRowHover: styles.hideOnRowHover,
    /**
     * Put this class on row items to override the behaviour of showOnRowHover
     * and always show them.
     */
    showAlways: styles.showAlways
  }
};