export const getMaxLengthValidator = maxLength => value => !value || typeof value !== 'string' || value.length <= maxLength;
export const getMaxLengthValidationError = (name, maxLength) => "".concat(name, " can be up to ").concat(maxLength, " characters");
export const getMinLengthValidator = minLength => value => {
  return !value || typeof value !== 'string' || value.trim().length >= minLength;
};
export const getMinLengthValidationError = (name, minLength) => "".concat(name, " must be at least ").concat(minLength, " characters");
export const getPatternValidator = pattern => value => typeof value !== 'string' || !value || value.match(pattern) != null;

/** Validates no html has entered. */
export const noHtmlPatternValidator = getPatternValidator(/^((?!<\/?[a-zA-Z][\s\S]*>)[\s\S])*$/);
export const getNoHtmlPatternError = valueName => "".concat(valueName ? "".concat(valueName, " may") : 'May', " include only these special characters: _%+:;#()[]$*&,.'-");
export const zipCodeUsPatternValidator = getPatternValidator(/(^\d{5}$)|(^\d{5}-\d{4}$)/);
export const getGenericError = valueName => "".concat(valueName, " is invalid");
export const validateEmail = value =>
//this regex was taken from go - goplayground/validator - emailRegexString
getPatternValidator(
// eslint-disable-next-line no-control-regex
/^(?:(?:(?:(?:[a-zA-Z]|\d|[!#$%&'*+\-/=?^_`{|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(?:\.([a-zA-Z]|\d|[!#$%&'*+\-/=?^_`{  |}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|(?:(?:\x22)(?:(?:(?:(?:\x20|\x09)*(?:\x0d\x0a))?(?:\x20|\x09)+)?(?:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(?:(?:[\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(?:(?:(?:\x20|\x09)*(?:\x0d\x0a))?(\x20|\x09)+)?(?:\x22))))@(?:(?:(?:[a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(?:(?:[a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])(?:[a-zA-Z]|\d|-|\.|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*(?:[a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(?:(?:[a-zA-Z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(?:(?:[a-zA-Z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])(?:[a-zA-Z]|\d|-|\.|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*(?:[a-zA-Z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/)(value);